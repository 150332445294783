<template>
  <div class="product-wrap case-wrap">
    <Header is-active="case" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-case-a"></div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="txt-box">
          <p>
            本工程为某区污水处理工程。污水厂近期规模为5万m/天，远期规模为10万m/天。主要内容为粗格栅及提升泵池、细格栅及沉砂池、絮凝沉淀池、曝气生物滤池，加药间及机修间、污泥脱水间、综合楼、门卫等。设计范围：生产过程自动化系统，在线监测仪表，综合楼综合布线及安防监控系统。
            系统根据集中管理，分散控制的原则，在生产现场设置4个现场PLC控制站：</p>
          <p>1号车间级现场控制站：细格栅控制站（CP1），控制范围为粗格栅及提升泵池、细格栅及沉沙池、加药间。</p>
          <p>2号车间级现场控制站：脱水机房控制站，该站为脱水系统成套提供。</p>
          <p>3号车间级现场控制站：曝气生物滤池控制站（CP3），控制范围为曝气生物滤池及反冲洗池。</p>
          <p>4号车间级现场控制站：AAO池控制站（CP4），控制范围为变配电间、AAO池、二沉池、鼓风机房、絮凝斜管沉淀池及转盘滤池、紫外线消毒渠。</p>
        </div>

        <div class="auto-img mt-5">
          <img src="../../assets/img/case/a/pci_1.png" alt="案例">
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>客户价值</h4></div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-yitihua2"></i></div>
            <p>实现数据管理标准一体化</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-pingtaihua"></i></div>
            <p>实现工作管理平台化</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-keshihua"></i></div>
            <p>实现管网及设备可视化监管</p>
          </div>
        </div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-yujing"></i></div>
            <p>实现管网及设备安全实时预警</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-jieneng1"></i></div>
            <p>实现节能降耗</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-shuzihua"></i></div>
            <p>实现巡检维修数字化</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '污水处理工程,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控污水处理工程,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  name: 'Case',
  components: {
    Header,
    Footer
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.case-wrap {
  .banner-case-a {
    background: url("../../assets/img/case/a/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }
}

</style>
